#skills {
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.skillsTitle{
    font-size: 3rem;
    font-weight: 600;
    padding-top: 3rem;
    margin-bottom: 2.5rem;
}

.skillsDesc {
    font-weight: 300;
    font-size: 1.2rem;
    max-width: 55rem;
    padding: 0 2rem;
}

.skillsBars {
    margin: 2.5rem;
    width: 100vw;
    max-width: 80%;
    text-align: left;
}

.skillsBar{
    display: flex;
    margin: 1rem;
    padding: 1.5rem 2rem;
    border-radius: 0.5rem;
    background: rgb(50,50,50);
}

.skillsBarImg{
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin-right: 2rem;
}

.skillsBarText>p{
    font-size: 1rem;
    font-weight: 200;
    padding-top: 0.3rem;
}

@media screen and (max-width: 720px) {
    .skillsBarText>p{
        font-size: 2vw;
    }
}

@media screen and (max-width: 480px) {
    .skillsBarText>p{
        font-size: 3vw;
    }

    .skillsBarImg{
        height: 2.25rem;
        width: 2.25rem;
    }

    skillsBarText>h2{
        font-size: 5vw;
    }
}