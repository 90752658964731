#intro{
    height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.bg{
    position: absolute;
    top: 0rem;
    right: -5vw;
    z-index: -1;
    object-fit: cover;
    height: 100vh;
}

.introContent{
    height: 100vh;
    width: 100vw;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hey{
    font-size: 1.75rem;
    font-weight: 100;
}

.introName{
    color: #fad0f5;
}

.introPara{
    font-size: medium;
    font-weight: 300;
    letter-spacing: 1px;
    width: 515px;
}

.btn{
    align-items: center;
    appearance: none;
    background-color: #fff;
    border-radius: 2rem;
    border-style: none;
    box-shadow: #fad0f5 0 1px 3px -1px,#fad0f5 0 3px 5px 0,#fad0f5 0 1px 9px 0;
    box-sizing: border-box;
    color: #3c4043;
    cursor: pointer;
    display: inline-flex;
    fill: currentcolor;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 48px;
    justify-content: center;
    letter-spacing: .25px;
    line-height: normal;
    max-width: 100%;
    overflow: visible;
    padding: 2px 24px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: auto;
    will-change: transform,opacity;
    z-index: 0;
}
.btn:hover {
    background: #F6F9FE;
    color: rgb(30 30 30);
}
.btn:not(:disabled) {
    box-shadow: #fad0f5 0 1px 3px 0, black 0 4px 8px 3px;
}
.btn:not(:disabled):hover {
    box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}
.btn:not(:disabled):focus {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}
.btn:not(:disabled):active {
    box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}
.btn:disabled {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.btnImg{
    object-fit: cover;
    margin: 0 0.25rem;
    height: 1rem;
}

@media screen and (max-width: 1000px){
    .bg{
        right: -15vw;
    }
    .introContent{
        font-size: 7vw;
    }
    .hey{
        font-size: 3.5vw;
    }
}
@media screen and (max-width: 590px){
    .hey{
        font-size: 4.5vw;
    }
    .introContent{
        font-size: 8vw;
    }
    .introPara{
        width: 50vw;
    }
    .btn{
        top: 1rem;
    }
}
@media screen and (max-width: 400px){
    .bg{
        right: -25vw;
    }
}


@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .bg{
        top: -4.5rem;
    }

}

