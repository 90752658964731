.navbar{
    background: rgb(30,30,30);
    height: 5rem;
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
}

.logo{
    object-fit: cover;
    height: 4rem;
    width: 4rem;
}

.desktopMenuListItem{
    margin: 1rem;
    cursor: pointer;
}

.desktopMenuListItem:hover{
    color: #fad0f5;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid #fad0f5;
}


.desktopMenuBtn {
   align-items: center;
   appearance: none;
   background-color: #fff;
   border-radius: 2rem;
   border-style: none;
   box-shadow: black 0 1px 3px -1px,black 0 3px 5px 0,black 0 1px 9px 0;
   box-sizing: border-box;
   color: #3c4043;
   cursor: pointer;
   display: inline-flex;
   fill: currentcolor;
   font-family: "Google Sans",Roboto,Arial,sans-serif;
   font-size: 14px;
   font-weight: 500;
   height: 48px;
   justify-content: center;
   letter-spacing: .25px;
   line-height: normal;
   max-width: 100%;
   overflow: visible;
   padding: 2px 24px;
   position: relative;
   text-align: center;
   text-transform: none;
   transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
   user-select: none;
   -webkit-user-select: none;
   touch-action: manipulation;
   width: auto;
   will-change: transform,opacity;
   z-index: 0;
}
.desktopMenuBtn:hover {
    background: #F6F9FE;
    color: rgb(30 30 30);
}
.desktopMenuBtn:not(:disabled) {

}
.desktopMenuBtn:not(:disabled):hover {
    box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}
.desktopMenuBtn:not(:disabled):focus {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}
.desktopMenuBtn:not(:disabled):active {
    box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}
.desktopMenuBtn:disabled {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}



.desktopMenuImg{
    object-fit: cover;
    height: 1rem;
    width: 1rem;
    margin: .75rem;
}

.active{
    color: #fad0f5;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid #fad0f5;
}

.mobMenu{
    display: none;
    object-fit: cover;
    height: 1.8rem;
}

.navMenu{
    position: absolute;
    top: 4rem;
    right: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    min-width: 15rem;
    background: rgb(40, 40, 40);
    border-radius: 1rem;
}

.listItem{
    color: white;
    padding: 0.5rem 3rem;
    margin: 0.25rem;
    background: rgb(30, 30, 30);
}

@media screen and (max-width: 720px){
    .mobMenu{
        display: flex;
    }
    .desktopMenu{
        display: none;
    }
    .desktopMenuBtn{
        display: none;
    }
}



/*
@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
/*
    .logo{
        height: 2rem;
        width: 2rem;
    }
    .navbar{
        height: 5rem;
        width: 350px;
        margin: 0 auto;
        padding: 0rem 0rem;
    }
    .desktopMenuListItem{
        margin: 1rem 1.2rem;
        padding-left: .3rem;
        font-size: 12px;
        cursor: pointer;
    }
    .desktopMenuBtn{
        visibility: hidden;
    }
    .desktopMenuImg{
        visibility: hidden;
    }
}
 */